import React, { Component } from "react";
import ContactForm from "./contact-form";

import Footer from "./footer";
import { ListGroup, Container } from "react-bootstrap";

import styled from "styled-components";
import { Download } from "react-bootstrap-icons";

import SolutionOrderForm from "./msds/HSNipro_Solution_Order_Form_Fillable_RevA.pdf";
import RepairOrderForm from "./msds/HSNipro_Repair_Order_Form_Fillable_RevA.pdf";
import CalibrationOrderForm from "./msds/HSNipro_Meter_Calibration_Order_Form_Fillable_231215_RevB.pdf";
import PartsOrderForm from "./msds/HSNipro_Parts_Order_Form_Fillable_RevA.pdf"

const StyledDownload = styled(Download)`
  margin-right: 20px;
`;

const StyledContainer = styled(Container)`
  margin-top: 75px;
`;

class DownloadsPageOrderForms extends Component {
  render() {
    return (
      <div>
        <div class="jumbotron">
          <StyledContainer>
            <h2>Forms</h2>
            <br></br>
            <ListGroup>
              <ListGroup.Item style={{  backgroundColor: "#00B5E2", color: "white"}}>
                {" "}
                NIPRO Technical Solutions Order Forms
              </ListGroup.Item>
              <ListGroup.Item> 
                <a 
                  href={
                    CalibrationOrderForm +
                    "?pdf=meter%2calibration%2order%2form"
                  }
                  download
                ><StyledDownload size={20} />
                Meter Calibration Order Form
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href={
                    PartsOrderForm +
                    "?pdf=parts%2order%2form"
                  }
                  download
                ><StyledDownload size={20} />
                Parts Order Form 
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a href={RepairOrderForm + "?pdf=repair%2order%2form"} download>
                <StyledDownload size={20} />
                  Repair Order Form
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href={
                    SolutionOrderForm +
                    "?pdf=calibration%2solution%2order%2form"
                  }
                  download
                ><StyledDownload size={20} />
                Solution Order Form 
                </a>
              </ListGroup.Item>
              <em class="italicsFootnote">*Prices are subject to change.</em>  
            </ListGroup>
          </StyledContainer>
        </div>
        <ContactForm />

        <Footer />
      </div>
    );
  }
}

export default DownloadsPageOrderForms;
