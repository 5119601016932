import React from "react";
import { Row, Alert } from "react-bootstrap";
export const CoreValues = (props) => {
  return (
    <Row className="valuesContainer" id="history">
      <Alert className="valuesText" variant="light">
        <br />
        <Alert.Heading>
          Customer Support with personalized solutions{" "}
        </Alert.Heading>

        <p>
          We create long-term customer relationships by delivering exceptional
          quality and a personalized experience. Through adaptability, we exceed
          the needs of our customers.{" "}
        </p>
        <hr />
        <Alert.Heading>Quality is our Culture</Alert.Heading>

        <p>
          Quality is a realization of excellence as measured by the customers we
          service and support. A mindset of continuous improvement drives our
          daily actions and defines who we are as an organization.{" "}
        </p>
        <hr />
        <Alert.Heading>Accountability, doing the right thing </Alert.Heading>

        <p>
          We hold ourselves accountable to do what we say we are going to do. We
          fully understand all requirements and expectations with an
          uncompromising commitment to excellence.{" "}
        </p>
        <hr />
        <Alert.Heading>Teamwork is the catalyst for our success</Alert.Heading>

        <p>
          As a team, we deliver more collectively than we can separately.
          <br />
          Combining each other’s skills and strengths, we are able to achieve
          greater goals through a shared commitment to performance.{" "}
        </p>

        <hr />
        <Alert.Heading>
          Knowledge, the key to organizational development{" "}
        </Alert.Heading>

        <p>
          We are committed to continually develop our knowledge, skills, and
          capabilities to achieve our mission. By investing in ourselves, we are
          able to innovate our products and improve our processes, maintaining a
          forward momentum.{" "}
        </p>

        <hr />
        <Alert.Heading> Leadership through communication </Alert.Heading>

        <p>
          NIPRO Technical Solutions leadership has an inspiring vision
          that clarifies and focuses the work of the organization. Through
          communication and shared decision making, we instill a sense of
          ownership. Our leadership is receptive and accessible to ideas and
          recognizes positive contributions to lead
          NIPRO Technical Solutions to a strong future.{" "}
        </p>

        <hr />
        <Alert.Heading>Fulfillment is work worth doing </Alert.Heading>

        <p>
          We each feel rewarded when we meet personal and professional goals,
          realizing that our work makes a difference every day to the people we
          serve. We achieve our mission by living our values each and every day,
          enriching the lives of employees, customers and patients alike.{" "}
        </p>

        <hr />
        <Alert.Heading>
          "Far and away the best prize that life offers is the chance to work
          hard at work worth doing."
          <br /> <span id="quote">-Theodore Roosevelt</span>{" "}
        </Alert.Heading>
      </Alert>
    </Row>
  );
};
