import React, { Component } from "react";
import ContactForm from "./../contact-form";
import Footer from "./../footer.js";
import RepairServiceImg from "../images/repair-service.jpg";
import LandingBanner from "./landing-banner";
import { Row, Col, Jumbotron, Button } from "react-bootstrap";
import Banner from "../images/banner.jpg";

class RepairService extends Component {
  render() {
    return (
      <div>
        <div className="jumbotron contractManufacturingText">
          <img id="heroimage" src={Banner} alt="Circuit Board" />
          <br />
          <br />
          <Row id="repairService">
            <Col sm={12} lg={6}>
              <img
                src={RepairServiceImg}
                className="contractManufacturingImg"
                alt="Repair Service Tech"
              />
            </Col>
            <Col sm={12} lg={6}>
              <br />
              <p id="contractManufacturing">
                Our founding company, NIPRO Technical Solutions{" "}
                Technical Services, Inc., operates a renowned hemodialysis
                equipment Repair Service Center; with multiple programs
                dedicated to meeting timely turnaround needs.
                <br />
                <br />
                <a href="https://www.hstsvc.com/" target="_blank">
                  <Button variant="outline-secondary">
                    Click Here To Learn More
                  </Button>
                </a>
              </p>
            </Col>
          </Row>
        </div>
        <LandingBanner />
        <ContactForm />
        <LandingBanner />
        <Footer />
      </div>
    );
  }
}

export default RepairService;
