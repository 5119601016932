import React, { Component } from "react";
import { ListGroup, Container } from "react-bootstrap";

import styled from "styled-components";
import ContactForm from "./contact-form";
import Circuit from "./images/stockIMG.jpg";
import Footer from "./footer";
import {Helmet} from "react-helmet";

const StyledContainer = styled(Container)`
  margin-top: 75px;
`;
class CareersPage extends Component {
  render() {
    return (
      <div>
        <img id="heroimage" src={Circuit} alt="Circuit Board" />
        <div className="jumbotron contactJumbotron">
          <h1 id="careersHeader">
            Careers at NIPRO Technical Solutions
          </h1>

          <hr />
          <br />
          <p id="careers">
            {/*NIPRO Technical Solutions, Inc. is a
            rapidly growing 25-year-old medical based company. We place quality
            first; believing in relationship building with our customers and
            employees above all.
            <br />
            <br />*/}
            At NIPRO Technical Solutions, the day-to-day relationships
            that employees experience are what make our company culture truly
            thrive in a team building environment. Our workplace philosophy
            integrates a positive and uplifting work setting extending beyond
            the work week into company events and team building activities
            throughout the year. We are unified in giving back to our local
            community as well as the global medical community, all while
            maintaining a family-oriented workplace culture and atmosphere to
            nurture employee growth and success. 
            <br />
            <br />
            NIPRO Technical Solutions provides the tools and the
            environment needed to create the continually growing and evolving
            workplace that is revolutionizing the medical device industry and
            beyond.
            <br />
            <br />
            To ensure equal employment and advancement opportunity to all
            employees and applicants, our employment decisions are based on
            merit, qualifications, and abilities. We do not discriminate in
            employment opportunities or practices based on race, color,
            religion, sex, pregnancy, family care status, national origin,
            ethnicity, age, disability, genetic information, veteran status,
            military service obligation, lawful medical marijuana use, sexual
            orientation or gender identity, or any other legally protected
            classification.
            <br />
            <br />
            Resumes may be submitted to{" "}
            <a href="mailto:careers@niprotechnical.com">careers@niprotechnical.com</a>
            <br />
          </p>
         {/* <div >
          <div class='sk-ww-indeed-jobs' data-embed-id='229958'></div>
          <Helmet>
            <script src='https://widgets.sociablekit.com/indeed-jobs/widget.js' async defer></script>
          </Helmet>
        </div>*/}
          <br></br><br></br> {/* Added space jsg 11/15/2022*/}
          
          <ContactForm />

        </div>
        <Footer />
      </div>
    );
  }
}

export default CareersPage;
