import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import ContactForm from "./../contact-form";
import Footer from "./../footer";
import LandingBanner from "./landing-banner";
import Printing3D from "./../images/revisions/PrintingPlastic3D.jpg";

const TurnKeyTop = `Our state-of-the-art technology and years of experience combine to effortlessly bring your vision to market.`;
const TurnKeyBottom = `Your product success story is our success story!`;
class TurnKey extends Component {
  render() {
    return (
      <div id="turnKey" className="bodyContainer">
        <LandingBanner className="turnKeyText" text={TurnKeyTop} />

        <div class="jumbotron">
          <Row>
            <Col sm={12} lg={6}>
              <img
                src={Printing3D}
                id="servicesImg"
                alt="3D printing image"
                style={{ maxWidth: "600px" }}
              />
            </Col>
            <Col sm={12} lg={6}>
              <h2 className="headerText productshead">
              Turn-Key Manufacturing
              </h2>
              <p className="jumbotronText">
                Our leading turn-key manufacturing operations exemplify
                simplicity and efficiency for our clients. With over 25 years of
                experience and knowledge, and both ISO 13845 and FDA
                accreditation as Class 2 Medical Device Manufacturers,{" "}
                NIPRO Technical Solutions is the streamlined resource
                to produce your desired results. At{" "}
                NIPRO Technical Solutions, we have the infrastructure,
                resources, and highly regulated processes in place to drive your
                product to market, all while saving you valuable time and
                minimizing your costs.
                <br />
                <br />
                We are committed to building long term business relationships.
                Tell us your needs and we provide you with the custom process to
                realize your vision. There is no “one size fits all” at{" "}
                NIPRO Technical Solutions.
              </p>
            </Col>
          </Row>
        </div>
        <LandingBanner className="turnKeyText" text={TurnKeyBottom} />
        <div className="jumbotron contactJumbotron">
          <ContactForm />
        </div>
        <Footer />
      </div>
    );
  }
}

export default TurnKey;
