import React, { Component } from "react";
import { Jumbotron, Col, Row } from "react-bootstrap";
import ContactForm from "../contact-form";
import Footer from "../footer";
import LandingBanner from "../landing-banner";
import MotorComponent from '../images/revisions/MotorComponent.jpg'

//content ported from hsmsvc.com

const TurnKeyTop =  (
  <p>
    NIPRO Technical Solutions is the streamlined resource to produce your desired results.
  </p>
);
const TurnKeyBottom = `The supreme business relationship for all your contract manufacturing needs.`;
class ContractManufacturing extends Component {
  render() {
    return (
      <div id="turnKey" className="bodyContainer">
        <LandingBanner className="turnKeyText" text={TurnKeyTop} />
        <div class="jumbotron">
          <Row>
          <Col sm={12} lg={6}>
          <img src={MotorComponent} id="servicesImg" alt="motor image"  style={{maxWidth: "500px"}}/>
          </Col>
          <Col sm={12} lg={6}>
        <h2 className="headerText productshead">Contract Manufacturing</h2>
    
          <p className="jumbotronText">
         
            Choosing the right company for your contract manufacturing service
            needs is a critical decision for any size business project. Our
            smooth and quick production processes allow for lowered expenses,
            overall quality improvement, and the results you need.
            <br />
            <br />

            As an esteemed ISO certified and FDA regulated contract
            manufacturer, we have the established resources and specialized
            experience required to bring your contract manufacturing
            requirements to an elevated level of product delivery and client
            satisfaction.
            <br />
            <br />
            <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=yZ61zV7aiEKPav757gS-nSlEXTjpGhhEviQPsXQp-EBUMTQxQzM5NE5LT0pRSkg0WDBWUDc0N0tPOS4u&embed=true"
            target="_blank"
            rel="noopener noreferrer">Contact us </a> today to request information or to schedule a
            consultation.
          </p>
          </Col>
          </Row>
        </div>
        <LandingBanner className="turnKeyText" text={TurnKeyBottom} />
        <div className="jumbotron contactJumbotron">
          <ContactForm />
        </div>
        <Footer />
      </div>
    );
  }
}

export default ContractManufacturing;
