import { Row, Col, ListGroup, Alert } from "react-bootstrap";

import React, { Component } from "react";
import styled from "styled-components";

const Styledalert = styled(Alert)`
  margin: 20px;
  padding: 0px;
  border-style: solid;
  border-color: white;
  border-width: thin;
  background-color: #999999;
`;

class Resources extends Component {
  render() {
    return (

        
      <Styledalert variant="secondary" >
        <Row >

          <Col sm={12} md={4} style={{ marginLeft: "10px", marginBottom: "10px"}}>
          <h2>Upcoming Events</h2>
            <ListGroup>


              <ListGroup.Item>
              2024 DaVita BiG Gathering ~ Dallas, TX ~ October 8-10, 2024
              </ListGroup.Item>
              <ListGroup.Item>
              Kidney Week 2024, Presented by ASN ~ San Diego ~ October 24-27, 2024
              </ListGroup.Item>
              <ListGroup.Item>
              DTX42, Presented by NANT ~ Las Vegas ~ April 1-4, 2024
              </ListGroup.Item>

            </ListGroup>
          </Col>
          <Col sm={12} md={4} style={{ borderStyle: "none none none solid", borderColor: "white", borderWidth: "thick"}}>
          <h2>Resources</h2>
            <ListGroup>
            <ListGroup.Item >
                <a target="_blank" rel="noreferrer" href="https://nipro.com/">
                  Nipro Medical Corporation
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://dialysistechnical.freeforums.net/"
                >
                  Dialysis Discussion Forum
                </a>
              </ListGroup.Item>


              <ListGroup.Item>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.davita.com"
                >
                  DaVita Kidney Care
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col sm={12} md={3}>
          <h2 class="noVisibilityThing">.</h2>
            <ListGroup>
              <ListGroup.Item>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.dialysistech.net"
                >
                  NANT
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.bridgeoflifeinternational.org"
                >
                  Bridge of Life
                </a>
              </ListGroup.Item>

              <ListGroup.Item>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.campnotawheeze.org"
                >
                  Camp-Not-A-Wheeze
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Styledalert>
    );
  }
}

export default Resources;
