import React, { Component } from "react";
import { Col, Button, Row, Card } from "react-bootstrap";
import quality from "./../images/hsm-revisions/QualityButtonStockImage.jpg";
import century from "./../images/hsm-revisions/CenturyImage.jpg";
import CircuitWorkClose from "./../images/revisions/CircuitWorkCloseUp.jpg";
import CardioQuip from "./../images/hsm-revisions/CardioQuipImage.jpeg";
import repairDepot from "./../images/hsm-revisions/RepairServiceDepotImage.JPG";
//import Sorin from "./../images/revisions/SorinStockImage.jpg";
import Nipro from "./../images/Nipro.jpg";

class LandingCards extends Component {
  render() {
    return (
      <Col sm={12} xl={12}>
        <Row className="equal">
          <Col sm={12} md={4} lg={4} className="LandingCardsCol">
            <Card className="landingCardsMobile" style={{ width: "18rem" }}>
              <Card.Img src={CircuitWorkClose} className="img-responsive" />
              <Card.Body>
                <Card.Title>Engineering</Card.Title>
                <Card.Text>
                NIPRO Technical Solutions will meet all
                  your design needs, specializing in mechanical, electrical, and
                  manufacturing engineering. We help inventors, start-ups and
                  major manufacturers with prototypes and beyond.  {" "}
                </Card.Text>
                <Button href="/engineering" variant="custom">
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col sm={12} md={4} lg={4} className="LandingCardsCol">
            <Card className="landingCardsMobile" style={{ width: "18rem" }}>
              <Card.Img src={century} className="img-responsive" />
              <Card.Body>
                <Card.Title>Turn-Key Manufacturing</Card.Title>
                <Card.Text>
                  We are the partner you need to get your idea to market. We
                  take care of everything from design to delivery.
                </Card.Text>
                <Button href="/turn-key-manufacturing" variant="custom">
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} lg={4} className="LandingCardsCol">
            <Card className="landingCardsMobile" style={{ width: "18rem" }}>
              <Card.Img src={CardioQuip} className="img-responsive" />
              <Card.Body>
                <Card.Title>Contract Manufacturing</Card.Title>
                <Card.Text>
                  Our contract manufacturing services are available for small
                  run production to large scale manufacturing. If you can dream
                  it, we can build it! 
                </Card.Text>
                <Button href="/contract-manufacturing" variant="custom">
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="equal" id="equalRow">
          <Col sm={12} md={4} lg={4} className="LandingCardsCol ">
            <Card className="landingCardsMobile" style={{ width: "18rem" }}>
              <Card.Img src={Nipro} className="img-responsive" />
              <Card.Body>
                <Card.Title>Distribution and Logistics</Card.Title>
                <Card.Text>
                We get your products to your customers - FAST. Let{" "}
                NIPRO Technical Solutions use our over 25 years of
                    experience in logistics to make it happen.
                </Card.Text>
                <Button href="/distribution-logistics" variant="custom">
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} lg={4} className="LandingCardsCol">
            <Card className="landingCardsMobile" style={{ width: "18rem" }}>
              <Card.Img src={quality} className="img-responsive" />
              <Card.Body>
                <Card.Title>
                  Quality
                  Systems
                </Card.Title>
                <Card.Text>
                  At NIPRO Technical Solutions, quality
                  is ingrained into who we are. Let us use our over 25 years of
                  experience to seamlessly develop a quality system for you.  
                </Card.Text>
                <Button href="/quality-systems" variant="custom">
                  Learn More
                </Button>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Col>
    );
  }
}

export default LandingCards;
