import React, { Component, useState } from "react";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ContactForm from "./contact-form";
import Footer from "./footer.js";
import LandingBanner from "./landing-banner";
import {
  Row,
  Tabs,
  Tab,
  Col,
  Jumbotron,
  Button,
  ListGroup,
  Card,
  Accordion
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import styled from "styled-components";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import CalibrationLab from "./images/cell.jpg";
import scrollToComponent from "react-scroll-to-component";
import FBCLogo from "./images/FBCLogo.jpg";
import FBCPdf1 from "./Fluke-User-Manuals/177-efsp-datasheet.pdf";
import FBCPdf2 from "./Fluke-User-Manuals/DVM177-User-Manual.pdf";
import FBCPdf3 from "./Fluke-User-Manuals/ESA612-Datasheet.pdf";
import FBCPdf4 from "./Fluke-User-Manuals/ESA612-Getting-Started-Manual.pdf";
import FBCPdf5 from "./Fluke-User-Manuals/ESA612-User-Manual.pdf";
import PHBuffer from "./images/SolutionVariety_NoBackground_201106.jpg";
import Calibrationlab2 from "./images/newImages/calLabCertPaperwork.jpg";
import TDS442 from "./msds/442TDSConductivityStandardSolutionMaterialSafetyDataSheet.pdf";
import ConductivitySolutionNaCI from "./msds/ConductivitySolutionNaCIMaterialSafetyDataSheet.pdf";
import HSCellCleaningSolution from "./msds/HSCellCleaningSolutionMaterialSafetyDataSheet.pdf";
import KCITDS from "./msds/KCITDSConductivityStandardSolutionMaterialSafetyDataSheet.pdf";
import PH401 from "./msds/pH4-01-BufferSolutionMaterialSafetyDataSheet.pdf";
import PH70 from "./msds/pH7-0-BufferSolutionMaterialSafetyDataSheet.pdf";
import PH825 from "./msds/pH8-25-BufferSolutionMaterialSafetyDataSheet.pdf";
import PH10 from "./msds/pH10-0-BufferSolutionMaterialSafetyDataSheet.pdf";
import OrderForm from "./msds/HSNipro_Solution_Order_Form_Fillable_RevA.pdf";
import CalibrationOrderForm from "./msds/HSNipro_Meter_Calibration_Order_Form_Fillable_231215_RevB.pdf";
import MeterCalCatalog from "./msds/catalogs/HSNipro_Calibration_Catalog_20231214.pdf";
import CalibrationSolutionsCatalog from "./msds/catalogs/HSNipro_Solutions_Catalog_20240711.pdf";

import SymbolsGlossary from "./policies/SM_FRM_3000F2.pdf"

import PartsOrderForm from "./msds/HSPartsOrderForm220816.pdf"
import QueryParamsDemo from "./scrollerComponent.js";
 
const StyledChevronUp = styled(ChevronUp)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.3em;
`;
const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.4em;
`;
//custom accordion buttons with a toggling chevron
function CustomToggle({ children, eventKey, inputVariable}){
  const [isActive, setIsActive] = useState(inputVariable);
  //globalvariable is currently undefined
  const decoratedOnClick = useAccordionButton(eventKey, () =>
  [setIsActive(isActive => !isActive)],
  );

  return (
    <button
      type="button"
      class="accordionButton"
      onClick={decoratedOnClick}
    >
      {[children]}
      {isActive ? <StyledChevronUp size={20}/>: <StyledChevronDown size={20}/>}
    </button>
  );
}

const landingBannerText = (
  <p>
    NIPRO Technical Solutions is committed to going beyond regulatory
    compliance <br />for your medical device safety requirements
    to place patient care, time <br /> and cost saving needs at the forefront.
  </p>
);

class HSMMCS extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      showDataSheets: false,
      
    };
  }

  handleToggle() {
    this.setState({
      show: !this.state.show,
    });
  }

  handleToggleshowDataSheets() {
    this.setState({
      showDataSheets: !this.state.showDataSheets,
    });
  }


  //set starting accordion by getting window location

  render() {
    var firstAccordionArrow = true;
    var secondAccordionArrow = false;
    var thirdAccordionArrow = false;
    //set starting accordion by getting window location
    //this function largely does the same thing as QueryParamsDemo
    //but I couldnt get QueryParamsDemo to work for setting the accordion defaultActiveKey
    function setStartingAccordionDefault() {
      //check what accordion we want to be
      if (window.location.search == "?name=calibration-center" )
      {
        return("calibration-center");
      }
      else if  (window.location.search == "?name=calibration-reference-solutions" )
      {
        firstAccordionArrow = false;
        secondAccordionArrow = true;
        
        return("calibration-reference-solutions");
      }
      else if  (window.location.search == "?name=reference-meters" )
      {
        firstAccordionArrow = false;
        
        thirdAccordionArrow = true;
        return("reference-meters");
      }
     else //if its default
     {
      return("calibration-center");
     }    
    }

    return (
      <div>
        <QueryParamsDemo />    
        <div className="bodyContainer">
          <Row>
            <div class="jumbotron">
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <img
                    src={CalibrationLab}
                    className="img-responsive"
                    id="servicesImg"
                    alt="Calibration Lab"
                  />
                </Col>
                <Col sm={12} md={6} lg={6} id="servicesheaderp">
                  <div id="spacingDiv">
                    <br />
                    <h1 className="productshead">
                    Calibration,
                      Solutions & Reference Meters
                    </h1>

                    <p id="servicesHeaderText">
                    NIPRO Technical Solutions has taken the lead in
                      standardizing calibrations, solutions, and reference
                      meters within the biomedical community; delivering cost
                      effective and streamlined ordering options.{" "}
                    </p>
                    <Button
                      variant="custom"
                      onClick={() =>
                        scrollToComponent(this.Content, {
                          offset: -100,
                          align: "top",
                          duration: 1500,
                        })
                      }
                    >
                      Learn More
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <LandingBanner
              id="landingBannerServices"
              text={landingBannerText}
            />
          </Row>
    
        
          <Row
            className="productsTabContainer"
            id="products"
            ref={(section) => {
              this.Content = section;
            }}
            
          >
            <div className="jumbotron" id="fieldServiceEngineeringJumbo">
            {/* Accordion Start */}
          <Accordion defaultActiveKey={setStartingAccordionDefault()}  id="uncontrolled-tab-example">
            
            {/* Accordion 1 */}
              <Card 
                title="Calibration Center" id="firstTab" >
                   <Card.Header className="accordionHeader">
                   <Link className="link-title-accordion" to="/meters-calibration-solutions?name=calibration-center">
                            <CustomToggle eventKey="calibration-center" inputVariable={firstAccordionArrow} >     
                               <b>Calibration Center</b>
                            </CustomToggle>
                            </Link> 
                      </Card.Header>
                <Accordion.Collapse eventKey="calibration-center">
                  <Card.Body>                <Row>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img
                      src={Calibrationlab2}
                      className="img-responsive tabImg"
                      alt="Calibration Lab Secondary"
                    />
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      Our full service calibration center offers comprehensive
                      calibration, verification and repair services with quick
                      turnaround times and calibration certification included at
                      no additional cost. We also offer calibration service for
                      models no longer recognized by the original manufacturer.
                    </p>
                    <br />
                    <br />
                    <Button variant="formButtonCustom">
                      <a
                        href={CalibrationOrderForm}
                        target="_blank"
                        rel="noopener noreferrer"
                        class="formButtonLink"
                      >
                        Meter Calibration
                        Order Form
                      </a>
                    </Button>
                    <br />
                    <br />
                    <Button variant="formButtonCustom">
                      <a
                        href={MeterCalCatalog}
                        target="_blank"
                        rel="noopener noreferrer"
                        class="formButtonLink"
                      >
                        Meter Calibration Catalog_20231214
                      </a>
                    </Button>
                  </Col>
                </Row></Card.Body>
              </Accordion.Collapse>
            </Card>
{/*accordion part 2*/}
          <Card id="secondTab" className="productTabs" >
          <Card.Header className="accordionHeader">
            <Link className="link-title-accordion" to="/meters-calibration-solutions?name=calibration-reference-solutions">
              <CustomToggle eventKey="calibration-reference-solutions" inputVariable={secondAccordionArrow}  >
              <b>Calibration Reference Solutions</b>
              </CustomToggle>
            </Link>
          </Card.Header>
            <Accordion.Collapse eventKey="calibration-reference-solutions">
              <Card.Body>
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText" >
                    We manufacture N.I.S.T. traceable calibration solutions for your dialysis equipment verification, 
                    device maintenance, conductivity, and cell cleaning needs. 
                    Bottle sizes are 100% compatible with all solution bottle holders. 
                    Caps are pressure-sealed to prevent leakage and contamination. <br />
                      <br />
                      <Button variant="formButtonCustom">
                        <a
                          href={OrderForm}
                          target="_blank"
                          rel="noopener noreferrer"
                          class="formButtonLink"
                        >
                          Calibration
                          Solution Order Form
                        </a>
                      </Button>
                      <br />
                      <br />
                      <Button variant="formButtonCustom">
                        <a
                          href={CalibrationSolutionsCatalog}
                          target="_blank"
                          rel="noopener noreferrer"
                          class="formButtonLink"
                        >
                          Calibration Solutions Catalog_20240711
                        </a>
                      </Button>
                      <br />
                      <br />
                      <Button
                        variant="formButtonCustom"
                        onClick={this.handleToggleshowDataSheets.bind(this)}
                        className={
                          this.state.showDataSheets ? "hideItem" : "displayItem"
                        }
                      >
                         Calibration
                        Reference Solution Safety Information <ChevronDown></ChevronDown>
                      </Button>
                    </p>

                    <ListGroup
                      variant="flush"
                      className={
                        this.state.showDataSheets ? "displayItem" : "hideItem"
                      }
                    >
                    <ListGroup.Item>
                        <a
                          href={SymbolsGlossary}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="hstext ">H&S</span> Solution Symbols Glossary
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={TDS442}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          442 TDS
                          Conductivity Standard Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={HSCellCleaningSolution}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Cell Cleaning
                          Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={ConductivitySolutionNaCI}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Conductivity
                          Solution NaCI Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={KCITDS}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                           KCI TDS
                          Conductivity Standard Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={PH401}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                           pH 4.01 Buffer
                          Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={PH70}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                           pH 7.0 Buffer
                          Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={PH825}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                           pH 8.25 Buffer
                          Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href={PH10}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                           pH 10.0 Buffer
                          Solution Safety Data Sheet
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <a
                          href="javascript:void(0)"
                          onClick={this.handleToggleshowDataSheets.bind(this)}
                        >
                          Close
                        </a>
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img
                      src={PHBuffer}
                      className="img-responsive tabImg"
                      alt="PH Buffer Solution"
                    />
                  </Col>
                </Row>
             </Card.Body>
        </Accordion.Collapse>
      </Card>
{/*accordion part 3*/}
        <Card id="thirdTab" className="productTabs" >
        <Card.Header className="accordionHeader">
          <Link className="link-title-accordion" to="/meters-calibration-solutions?name=reference-meters">
            <CustomToggle eventKey="reference-meters" inputVariable={thirdAccordionArrow}  >                     
            <b>Reference Meters</b>
            </CustomToggle>
          </Link>
        </Card.Header>
            <Accordion.Collapse eventKey="reference-meters">
        <Card.Body>
                <p className="tabText">
                NIPRO Technical Solutions provides advanced quality
                  meter options by our distribution partnership with Fluke
                  Biomedical.
                </p>
                <Row>
                  <Col
                    sm={12}
                    md={12}
                    lg={12}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <Row>
                      <Col sm={12} md={4}>
                        <div id="flukeDiv">
                          <img
                            src={FBCLogo}
                            className="img-responsive fluke"
                            alt="Fluke Biomedical Logo"
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <p className="tabSubText">
                        NIPRO Technical Solutions are proud partners
                          with Fluke Biomedical as their second largest
                          distributor in North America. <br /> <br />
                          Current model offerings include both the Electrical
                          Safety Analyzer 612 for simplified one-touch testing,
                          and the Digital Multimeter 177 ESFP for electrical
                          troubleshooting.
                        </p>
                        
                        <Button variant="formButtonCustom">
                      <a
                        href={MeterCalCatalog}
                        target="_blank"
                        rel="noopener noreferrer"
                        class="formButtonLink"
                      >
                        Meter Calibration Catalog_20231214
                      </a>
                    </Button>
                        <br />
                        <br />

                        <Button variant="formButtonCustom">
                          <a
                            href={PartsOrderForm}
                            target="_blank"
                            rel="noopener noreferrer"
                            class="formButtonLink"
                          >
                            Parts Order Form
                          </a>
                        </Button>
                        <br />
                        <br />

                        <Button
                          variant="formButtonCustom"
                          onClick={this.handleToggle.bind(this)}
                          className={
                            this.state.show ? "hideItem" : "displayItem"
                          }
                        >
                          Additional Information
                        </Button>
                        <br />
                        <br />
                        <ListGroup
                          variant="flush"
                          className={
                            this.state.show ? "displayItem" : "hideItem"
                          }
                        >
                          <ListGroup.Item>
                            <a
                              href={FBCPdf1}
                              target="_blank"
                              rel="noopener noreferrer"
                              class="formButtonLink"
                            >
                              Fluke 177 EFSP Datasheet
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <a
                              href={FBCPdf2}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Fluke DVM 177 User Manual
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <a
                              href={FBCPdf3}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Fluke ESA612 Datasheet
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <a
                              href={FBCPdf4}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Fluke ESA612 Getting Started Manual
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <a
                              href={FBCPdf5}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Fluke ESA612 User Manual
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <a
                              href="javascript:void(0)"
                              onClick={this.handleToggle.bind(this)}
                            >
                              Close
                            </a>
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>                                  
                                  </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>

                   
            </div>
          </Row>
          <Row>
          
            <LandingBanner />
          </Row>
          <ContactForm />
        </div>
        <Footer />
      </div>
    );
  }
}

export default HSMMCS;
