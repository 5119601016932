import React, { Component } from "react";
import ContactForm from "./contact-form";
import Footer from "./footer.js";
// import ManufacturingImg from './images/DSC08550.jpg'
import LandingBanner from "./landing-banner";
import Circuit from "./images/stockIMG.jpg";
class PrivacyPolicy extends Component {
  render() {
    return (
      <div>
        <img id="heroimage" src={Circuit} alt="Circuit Board" />
        <div className="jumbotron privacyPolicy">
          <h3 id="privacyHeader">Privacy Policy</h3>
          Thank you for taking the time to read the Privacy Policy for NIPRO Technical Solutions, Inc. 
          This privacy policy discloses the privacy practices for www.niprotechnical.com.
This privacy notice applies solely to information collected by this website. 
Any changes to this privacy policy will be posted herein.

          <br />
          <br />
          <b>Information Collection, Use, and Sharing </b>
          <br />
          NIPRO Technical Solutions, Inc. is the sole owners of the information collected on this site. 
          We only collect information that you voluntarily provide via email, live chat, or when entering information on our website. 
          <br />
          <br />
          We will use your information to respond to you and will not share your information with
          any third party outside of our organization, other than as necessary to fulfill your request.
          <br />
          We may use the information we collect from you to process
          transactions, send periodic emails or newsletters, or to follow up an
          inquiry.
          <br />
          You may opt out of any future contact from NIPRO Technical Solutions, Inc. at any time 
          by contacting us via sales@niprotechnical.com or the phone number given on this website.
          <br />
          <br />
          <b>Google Analytics and Cookies</b>
          <br />
          This website uses Google Analytics, a service which communicates
          website traffic data to Google servers in the United States. Google
          Analytics does not track individual users or associate your IP Address
          with any other data held by Google. We use this data provided by
          Google to better understand our website traffic and usage.
          <br />
          <br />
          Google Analytics uses ‘cookies’. A cookie is a small text file that
          Google Analytics may place on your computer as a tool to remember your
          preferences when on our site. You may opt out of the use of cookies by
          selecting the appropriate settings on your browser, however, please
          note that you may not be able to use the full functionality of this
          website. Usage of a cookie is in no way linked to any Personally
          Identifiable Information (PII) on our site.
          <br />
          <br />
          <b>Resource Links</b>
          <br />
          Our website may periodically contain links to websites belonging to
          other companies. NIPRO Technical Solutions is not
          responsible for the content or privacy practices of other sites.
          Please be aware and read the privacy statements of any other site that
          collects Personally Identifiable Information (PII).
          <br />
          <br />
          <b>Children’s Online Privacy Protection Act (COPPA)</b>
          <br />
          NIPRO Technical Solutions Technical Services, Inc.
          website is not intended for children under the age of 13. We will not
          knowingly collect personally identifiable information from children
          under 13 years of age. Any communications that are identified as being
          from a child under the age of 13 will not be retained.
          <br />
          <br />
          <b>Contact Us</b>
          <br />
          If you have any questions or comments regarding this privacy policy,
          please contact us:
          <br />
          <i>
            Mailing Address:
            <br />
            NIPRO Technical Solutions,
            Inc.
            <br />
            3110 N. Oakland, Suite 101
            <br />
            Mesa, AZ 85215
            <br />
            Email: sales@niprotechnical.com
            <br />
          </i>
          <br />
          <i>Last edited on 10/1/2024</i>
          <br />
        </div>
        <LandingBanner />
        <ContactForm />
        <LandingBanner />
        <Footer />
      </div>
    );
  }
}

export default PrivacyPolicy;
