import React, { Component, useState } from "react";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {
  Row,
  Col,
  Button,
  ListGroup,
  Accordion,
  Card,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom";
import styled from "styled-components";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import ContactForm from "./contact-form";
import Footer from "./footer.js";
import Repair from "./images/newImages/DECircuitCardsCropped.jpg";
import Exchange from "./images/RevisedImages/DSC02572.JPG";
import Recycle from "./images/RevisedImages/DSC02634_Cropped.jpg";
import Water from "./images/RevisedImages/DSC02599_Cropped.jpg";
import LandingBanner from "./landing-banner";
import ServiceRepair from "./images/RevisedImages/DSC02637_Cropped.jpg";
import scrollToComponent from "react-scroll-to-component";
import OrderForm from "./msds/HSNipro_Repair_Order_Form_Fillable_RevA.pdf";

import BBRAUNCatalog from "./msds/catalogs/HSNipro_BBRAUN_Catalog_20230216_RevA.pdf";
import FreseniusCatalog from "./msds/catalogs/HSNipro_Fresenius_Catalog_20230117.pdf";
import GambroCatalog from "./msds/catalogs/HSNipro_Gambro_Catalog_20231117.pdf";
import PartsOrderForm from "./msds/HSNipro_Parts_Order_Form_Fillable_RevA.pdf"

import QueryParamsDemo from "./scrollerComponent.js";


const StyledChevronUp = styled(ChevronUp)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.3em;
`;
const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  right: 0;
  margin-right: 0.4em;
  margin-top: 0.4em;
`;
//custom accordion buttons with a toggling chevron
function CustomToggle({ children, eventKey, inputVariable}){
  const [isActive, setIsActive] = useState(inputVariable);
  //globalvariable is currently undefined
  const decoratedOnClick = useAccordionButton(eventKey, () =>
  [setIsActive(isActive => !isActive)],
  );

  return (
    <button
      type="button"
      class="accordionButton"
      onClick={decoratedOnClick}
    >
      {[children]}
      {isActive ? <StyledChevronUp size={20}/>: <StyledChevronDown size={20}/>}
    </button>
  );
}


const ServicesText =
  "Our Team Believes in Quality Services and Striving to Exceed Our Customers' Expectations.";

class HSMServices extends Component {
  render() {
    
    var firstAccordionArrow = true;
    var secondAccordionArrow = false;
    var thirdAccordionArrow = false;
    var fourthAccordionArrow = false;
    //set starting accordion by getting window location
    //this function largely does the same thing as QueryParamsDemo
    //but I couldnt get QueryParamsDemo to work for setting the accordion defaultActiveKey
    function setStartingAccordionDefault() {
      //check what accordion we want to be
      if (window.location.search == "?name=repair-program" )
      {
        return("repair-program");
      }
      else if  (window.location.search == "?name=exchange-program" )
      {
        firstAccordionArrow = false;
        secondAccordionArrow = true;
        return("exchange-program");
      }
      else if  (window.location.search == "?name=recycle-program" )
      {
        firstAccordionArrow = false;
        thirdAccordionArrow = true;
        return("recycle-program");
      }
      else if  (window.location.search == "?name=water-treatment-service-center" )
      {
        firstAccordionArrow = false;
        fourthAccordionArrow = true;
        return("water-treatment-service-center");
      }
     else //if its default
     {
      return("repair-program");
     }    
    }
    return (
      <div>
        <QueryParamsDemo />  
        <div className="bodyContainer">
          <Row>
            <div class="jumbotron">
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <img
                    src={ServiceRepair}
                    className="img-responsive"
                    id="servicesImg"
                    alt="Repair Technician"
                  />
                </Col>
                <Col sm={12} md={6} lg={6} id="servicesheaderp">
                  <div id="spacingDiv">
                    <br />
                    <br />
                    <br />
                    <h1 className="productshead">
                     Parts Services
                    </h1>
                    <p id="servicesHeaderText">
                      Our Repair Service, Exchange and Recycle Parts
                      Programs allow for parts to be received faster and at a
                      cost savings of up to 60% when compared to new
                      replacements. We test to the highest level of quality
                      standards, backed by our signature 6-month, hassle-free
                      warranty policy for all repair services. {" "}
                    </p>
                    <br />
                    <Row>
                      <Col sm={12} lg={6}>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <a
                              href={FreseniusCatalog}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                               Fresenius Catalog_20230117
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <a
                              href={BBRAUNCatalog}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                               BBRAUN
                              Catalog_20230216
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <a
                              href={GambroCatalog}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                               Gambro Catalog_20231117
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <a
                              href={PartsOrderForm}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                               Parts Order Form
                            </a>
                          </ListGroup.Item>
                          {/*Added 11/15/2022 */}
                          <ListGroup.Item>
                            <a
                              href={OrderForm}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Nipro Repair Order Form
                            </a>
                          </ListGroup.Item>                           
                        </ListGroup>
                        <br />
                        <Button
                          variant="custom"
                          onClick={() =>
                            scrollToComponent(this.Services, {
                              offset: 0,
                              align: "top",
                              duration: 1500,
                            })
                          }
                        >
                          Learn More
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <LandingBanner id="landingBannerServices" text={ServicesText} />
          </Row>
          <Row
            id="serviceTabContainer"
            ref={(section) => {
              this.Services = section;
            }}
          >
            <div className="jumbotron" id="fieldServiceEngineeringJumbo">
            {/*Start of Accordion */}
          <Accordion
              defaultActiveKey={setStartingAccordionDefault()}
              id="uncontrolled-tab-example"
            >
              {/*Accordion 1*/}
            <Card className = "serviceTags" title="Repair Program" id="firstTab">
            <Card.Header className="accordionHeader">
              <Link  to="/services?name=repair-program">
                <CustomToggle eventKey={"repair-program"} inputVariable={firstAccordionArrow}>     
                <b>Repair Program</b>
                </CustomToggle>
              </Link> 
            </Card.Header>
              <Accordion.Collapse eventKey={"repair-program"} >
              <Card.Body>
                <Row>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img
                      src={Repair}
                      className="img-responsive tabImg"
                      id="smallTabImg"
                      alt="Repair Center"
                    />
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      We offer exceptional repair services for a variety of
                      dialysis machine brands, including all Fresenius, Gambro
                      Phoenix (Baxter), and B. Braun models. All our service
                      repairs undergo extensive analysis and are tested in fully
                      maintained and calibrated machines.
                    </p>
                    <br />
                    <br />
                    <a
                      href={OrderForm}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button variant="custom">
                         Repair Order Form
                      </Button>
                    </a>
                  </Col>
                </Row></Card.Body>
              </Accordion.Collapse>
            </Card>
            {/*Accordion 2*/}
            <Card className = "serviceTags" title="Exchange Program" id="secondTab">
            <Card.Header className="accordionHeader">
              <Link className="link-title-accordion" to="/services?name=exchange-program">
                <CustomToggle eventKey={"exchange-program"} inputVariable={secondAccordionArrow}>     
                  <b>Exchange Program</b>
                </CustomToggle>
              </Link> 
            </Card.Header>
              <Accordion.Collapse eventKey={"exchange-program"} >
              <Card.Body>            
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      We have what you need, in stock and ready to ship. Postage
                      labels are always provided for the return of defective
                      components. This program truly allows you to receive parts
                      both faster and at a fraction of the cost than the
                      competition.
                    </p>
                  </Col>
                  <Col sm={12} md={6} lg={6} className="tabCol">
                    <img
                      src={Exchange}
                      className="img-responsive tabImg"
                      alt="Exhange Products"
                    />
                  </Col>
                  </Row></Card.Body>
              </Accordion.Collapse>
            </Card>
            {/*Accordion 3*/}
            <Card className = "serviceTags" title="Recycle Program" id="thirdTab">
            <Card.Header className="accordionHeader">
              <Link className="link-title-accordion" to="/services?name=recycle-program">
                <CustomToggle eventKey={"recycle-program"} inputVariable={thirdAccordionArrow}>     
                <b>Recycle Program</b>
                </CustomToggle>
              </Link> 
            </Card.Header>              
              <Accordion.Collapse eventKey={"recycle-program"} >
              <Card.Body>
                <Row>
                  <Col sm={12} md={4} lg={4} className="tabCol">
                    <img
                      src={Recycle}
                      className="img-responsive tabImg"
                      alt="Recycle Center"
                    />
                  </Col>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                      Our Recycle Program was developed specifically to save you
                      money. With inventory directly from dialysis machines,
                      each part has been thoroughly cleaned, tested, and
                      disinfected for resale to you. This reconditioned parts
                      program option is not only cost effective, but
                      environmentally friendly as it prevents landfill excess. 
                    </p>
                  </Col>
                  </Row></Card.Body>
              </Accordion.Collapse>
            </Card>
            {/*Accordion 4*/}
            <Card className = "serviceTags" title="Water Treatment Service Center" id="fourthTab">
            <Card.Header className="accordionHeader">
              <Link className="link-title-accordion" to="/services?name=water-treatment-service-center">
                <CustomToggle eventKey={"water-treatment-service-center"} inputVariable={fourthAccordionArrow}>     
                  <b>Water Treatment Service Center</b>
                </CustomToggle>  
              </Link> 
            </Card.Header>
              <Accordion.Collapse eventKey={"water-treatment-service-center"} >
              <Card.Body>              
                <Row>
                  <Col
                    sm={12}
                    md={8}
                    lg={8}
                    id="servicesheaderp"
                    className="tabCol"
                  >
                    <p className="tabText">
                    NIPRO Technical Services proudly services a variety of water treatment systems
                      and equipment, including the B. Braun AQUABoss and the WRO
                      300 Dialysis Water Purification System. This program is
                      designed for critical equipment services while maintaining
                      your current unique system modifications.
                    </p>
                  </Col>
                  <Col sm={12} md={4} lg={4} className="tabCol">
                    <img
                      src={Water}
                      className="img-responsive tabImg"
                      alt="Water Treatment"
                    />
                  </Col>
                  </Row></Card.Body>
              </Accordion.Collapse>
            </Card>
            </Accordion>
            </div>
          </Row>
          <LandingBanner />
          <ContactForm />
        </div>
        <Footer />
      </div>
    );
  }
}

export default HSMServices;
