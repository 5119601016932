import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col, Alert, Button } from "react-bootstrap";
import LandingBanner from "./landing-banner";
import LandingCards from "./landing-cards";
import LandingCardsD from "./landing-cards-mobile";
import ContactForm from "./contact-form";
import Footer from "./footer.js";
// import Circuit from "./images/stockIMG.jpg";
import TextScroller from "./textScroller";
import Resources from "./resources";
import Testimonials from "./testimonials";

const middleBanner = (
  <p class="noItalics">
    
    Proudly&nbsp;Serving&nbsp;the&nbsp;Dialysis&nbsp;Community
    for&nbsp;Over&nbsp;25&nbsp;Years.
  </p>
);


const bottomBanner = (
  <p class="noItalics">
    We’ve got you covered with parts services for 2008K, 2008K2, 2008T & 2008T
    BlueStar!
  </p>
);

const StyledCookieAlert = styled(Alert)`
background-color: #00B5E2;
  position: fixed;
  bottom: 20px;
  width: 45%;
  left 27.5%;
  z-index: 700;
  @media(max-width: 500px){
    width: 100%;
    left: 0;
  }
`;
const StyledWebsiteUpdateAlert = styled(Alert)`

  position: fixed;
  bottom: 10px;
  width: 70%;
  left 15%;
  z-index: 600;
  @media(max-height: 900px){
    width: 80%;
    left: 10%;
      height:50%;
       overflow:auto;
  }
  @media(max-width: 1000px){
    width: 80%;
    left: 10%;
      height:650px;
       overflow:auto;
  }
`;
const AlertDismissible = (props) => {
  const alertOverride = localStorage.getItem("hideAlert");
 
  return (
    <>
      <StyledCookieAlert
        show={alertOverride ? !alertOverride : props.showAlert}
        variant="dark"
      >
        <Alert.Heading>Cookies Usage</Alert.Heading>
        <p>
          This site uses cookies to provide you with a great user experience. By
          using this website, you accept our{" "}
          <a href="/privacy-policy"> use of cookies</a>
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => props.hideAlert()} variant="outline-dark">
            I accept.
          </Button>
        </div>
      </StyledCookieAlert>
    </>
  );
};
const WebsiteAlertDismissible = (props) => {
  const websiteAlertOverride = localStorage.getItem("hideUpdateAlert");
 
  return (
    <>
      <StyledWebsiteUpdateAlert
        show={websiteAlertOverride ? !websiteAlertOverride : props.showUpdateAlert}
        variant="dark"
      >
        <p>
        Dear Valued Customer, Vendor, and Partner: <br /><br />
We are pleased to announce that H&S Technical Services, a Nipro company, is transitioning to NIPRO Technical Solutions, Inc., effective immediately. 
This change reflects our commitment to enhancing technical solutions and sustainable products that support your vital mission to serve patients. <br/><br/>
As a subsidiary of NIPRO Corporation Japan, established in 1954, we focus on optimizing patient outcomes through innovative technical solutions for life-saving medical devices. Our dedication to quality and patient safety remains paramount as we continue to evolve our offerings to meet your needs.
<br/><br/>
Key Details:
<br/>
<br/>New Name: NIPRO Technical Solutions, Inc.
<br/>Effective Date: August 1, 2024
<br/>Website: niprotechnical.com
<br/>Email: sales@niprotechnical.com
<br/>Billing Address: NIPRO Technical Solutions, Inc., 3110 N. Oakland, Suite 101, Mesa, AZ 85215
<br/>
<br />Thank you for your continued trust and partnership. We remain committed to serving you and the healthcare community, delivering the best solutions to help create a world where everyone can Live Longer. Live Better. 
<br />
<br />Sincerely, 
<br />
<br />Renata Zugaj
<br />General Manager
<br />NIPRO Technical Solutions, Inc.

        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => props.hideUpdateAlert()} variant="outline-dark">
            Close
          </Button>
        </div>
      </StyledWebsiteUpdateAlert>
    </>
  );
};
class HSMLanding extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      width: window.innerWidth,
      showCovidModal: false,
      showAlert: true,
      showUpdateAlert: true,
    };
  }

  hideAlert = () => {
    this.setState({ showAlert: !this.state.showAlert });
    localStorage.setItem("hideAlert", true);
  };
  hideUpdateAlert = () => {
    this.setState({ showUpdateAlert: !this.state.showUpdateAlert });
    localStorage.setItem("hideUpdateAlert", true);
  };

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  renderLandingCards = () => {
    const width = this.state.width;
    const isMobile = width <= 800;
    return isMobile ? <LandingCards /> : <LandingCardsD />;
  };

  // <CarouselComp  />

  render() {
    return (
      <div>
        <div className="bodyContainer">
          <Row>
            <LandingBanner id="landingBannerFirst" text={middleBanner} />
          </Row>

          <Row id="LandingCardsRow">{this.renderLandingCards()}</Row>
          <Row>
            <TextScroller text={bottomBanner} />
          </Row>
          <Row id="testimonials">
            <Col xs={12} lg={12}>
              <Testimonials />
            </Col>
          </Row>

          <ContactForm />
          <Resources />
        </div>
        {
          <AlertDismissible
            showAlert={this.state.showAlert}
            hideAlert={this.hideAlert}
          />
          
        }
{
            <WebsiteAlertDismissible
            showUpdateAlert={this.state.showUpdateAlert}
            hideUpdateAlert={this.hideUpdateAlert}
          />
}
        <Footer />
      </div>
    );
  }

}
export default HSMLanding;
